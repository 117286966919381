import { useLazyQuery } from "@apollo/client";
import { useAppDispatch } from "../../../redux/hook";
import { bankingClient } from "../../../apolloClient";
import { useEffect } from "react";
import { _myInformation, MyInformationItem } from "../../../indexDB/data";
import { LedgerInfo_ledgerInfo } from "../../../graphql/graphql_banking/legder/types/LedgerInfo";
import { ALL_LEDGERS } from "../../../graphql/graphql_banking/legder/query";
import { setAllLedger } from "../../../redux/ledger/ledgerSlice";
import { AllLedgers } from "../../../graphql/graphql_banking/legder/types/AllLedgers";

const useAllLedger = (synchronize?: boolean) => {
  const dispatch = useAppDispatch();

  const [getAll, { loading, data }] = useLazyQuery<AllLedgers>(ALL_LEDGERS, {
    fetchPolicy: "no-cache",
    client: bankingClient,
    async onCompleted(data) {
      if (data?.allLedgers) {
        await _myInformation.setItem(
          MyInformationItem.ALL_LEDGERS,
          data.allLedgers,
        );
        await setData(data.allLedgers);
      }
    },
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const cache = await _myInformation.getItem(MyInformationItem.ALL_LEDGERS);
    if (cache && !synchronize) {
      setData(cache as LedgerInfo_ledgerInfo[]);
      return;
    }
    getAll();
  };

  const setData = async (data: (LedgerInfo_ledgerInfo | null)[]) => {
    dispatch(setAllLedger(data));
  };

  return { getAll, loading, data };
};

export default useAllLedger;
