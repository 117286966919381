// ToastProvider.tsx
import React, { createContext, useContext, useState, useCallback } from "react";
import { Snackbar, Slide } from "@material-ui/core";
import type { SlideProps } from "@material-ui/core/Slide";
import { Alert } from "@material-ui/lab";

// 1️⃣ Types
export type ToastSeverity = "success" | "info" | "warning" | "error";

export interface ToastOptions {
  id?: string; // sera généré si pas fourni
  open?: boolean; // pour MUI
  title?: React.ReactNode;
  description?: React.ReactNode;
  severity?: ToastSeverity;
  autoHideDuration?: number;
}

// 2️⃣ Contexte
export interface ToastContextType {
  toast: (options: Omit<ToastOptions, "id">) => {
    id: string;
    dismiss: () => void;
  };
  dismiss: (id?: string) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

// 3️⃣ Hook useToast
export function useToast(): ToastContextType {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used inside a ToastProvider");
  }
  return context;
}

// 4️⃣ Transition Slide (optionnelle)
function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

// 5️⃣ ToastProvider
export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [toasts, setToasts] = useState<ToastOptions[]>([]);

  // Pour stocker les timeouts, si on veut un système similaire
  const toastTimeoutsRef = React.useRef<
    Map<string, ReturnType<typeof setTimeout>>
  >(new Map());

  // Ajout d’un toast
  const addToast = useCallback((opts: Omit<ToastOptions, "id">) => {
    const id = Date.now().toString(); // ou un autre générateur
    const newToast: ToastOptions = {
      ...opts,
      id,
      open: true,
    };
    setToasts((prev) => [newToast, ...prev]);

    // Méthode pour fermer ce toast
    const dismiss = () => {
      setToasts((prevToasts) =>
        prevToasts.map((t) => (t.id === id ? { ...t, open: false } : t)),
      );
    };

    return { id, dismiss };
  }, []);

  // Fermeture d’un toast par ID (ou tous si pas d’ID)
  const dismiss = useCallback((toastId?: string) => {
    if (toastId) {
      setToasts((prevToasts) =>
        prevToasts.map((t) => (t.id === toastId ? { ...t, open: false } : t)),
      );
    } else {
      // Ferme tous
      setToasts((prevToasts) => prevToasts.map((t) => ({ ...t, open: false })));
    }
  }, []);

  // Quand un toast se ferme, on le retire
  const handleClose = (id: string) => {
    setToasts((prevToasts) => prevToasts.filter((t) => t.id !== id));
  };

  return (
    <ToastContext.Provider value={{ toast: addToast, dismiss }}>
      {children}

      {/* Rendu de tous les toasts via MUI Snackbar + Alert */}
      {toasts.map((toast) => (
        <Snackbar
          key={toast.id}
          open={toast.open}
          autoHideDuration={toast.autoHideDuration ?? 5000}
          onClose={() => dismiss(toast.id)}
          TransitionComponent={SlideTransition}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          // Au moment où le Snackbar se ferme vraiment, on retire le toast
          onExited={() => handleClose(toast.id!)}
        >
          <Alert
            variant="filled"
            severity={toast.severity || "success"}
            onClose={() => dismiss(toast.id)}
          >
            {toast.title && <strong>{toast.title}</strong>}
            {toast.description && <div>{toast.description}</div>}
          </Alert>
        </Snackbar>
      ))}
    </ToastContext.Provider>
  );
};
