export default {
  BENEFICIARY_ACCOUNT_CLOSED_OR_STOPPED:
    "Le compte du bénéficiaire est fermé ou bloqué.",
  BENEFICIARY_INFORMATION_MISSING:
    "Des informations sur le bénéficiaire manquent.",
  BENEFICIARY_INFORMATION_INVALID:
    "Les informations du bénéficiaire sont incorrectes.",
  BENEFICIARY_NAME_DOES_NOT_MATCH_BENEFICIARY_ACCOUNT:
    "Le nom fourni ne correspond pas à celui du compte bénéficiaire.",
  BENEFICIARY_NOT_ENABLED_FOR_INSTANT_PAYMENTS:
    "Le bénéficiaire ne peut pas recevoir de paiements instantanés.",
  REFERENCE_INVALID: "La référence de la transaction est erronée.",
  SENDER_ACCOUNT_CLOSED: "Le compte de l’expéditeur est fermé.",
  SENDER_INFORMATION_INVALID:
    "Les informations de l’expéditeur sont incorrectes.",
  AMOUNT_OR_CURRENCY_INVALID: "Le montant ou la devise est invalide.",
  INSUFFICIENT_FUNDS: "Le solde insuffisant.",
  TRANSACTION_CURRENCY_DIFFERENT_TO_RECEIVER_ACCOUNT:
    "La devise de la transaction ne correspond pas à celle du compte du bénéficiaire.",
  LIMITS_EXCEEDED: "La transaction dépasse les limites autorisées.",
  TRANSACTION_FAILED: "La transaction a échoué.",
  TRANSACTION_UNAUTHORISED: "La transaction n’est pas autorisée.",
  TRANSACTION_CANCELLED: "La transaction a été annulée.",
  RETURNED_VIA_RECALL: "La transaction a été renvoyée sur demande de rappel.",
  REJECTED_BY_RECEIVING_BANK:
    "La banque du bénéficiaire a rejeté la transaction.",
  REJECTED_COMPLIANCE_REASON:
    "La transaction est rejetée pour des raisons de conformité.",
  REJECTED_NOT_ROUTABLE:
    "La transaction ne peut pas être acheminée correctement.",
  REJECTED_NO_SPECIFIED_REASON:
    "La transaction est rejetée sans raison précise.",
  REJECTED_ACCOUNT_CLOSED: "Le compte concerné est fermé.",
  REJECTED_ACCOUNT_INVALID: "Le compte concerné est invalide.",
  REJECTED_TECHNICAL_ISSUE:
    "Une erreur technique a causé le rejet de la transaction.",
  UNSUPPORTED_CARD_TYPE: "Le type de carte n’est pas supporté.",
  UNSUPPORTED_ISSUING_COUNTRY: "Le pays d’émission n’est pas autorisé.",
  THREEDS_ERROR: "Une erreur 3D Secure est survenue.",
  RISK_CHECK_ERROR: "La vérification de risque a échoué.",
  MISSING_FIELD: "Un champ obligatoire manque.",
  INVALID_FIELD: "Un champ contient des informations incorrectes.",

  // CARD
  REFER_TO_CARD_ISSUER: "Veuillez contacter l'émetteur de la carte.",
  INVALID_MERCHANT: "Le commerçant est invalide.",
  DO_NOT_HONOUR: "La transaction est refusée.",
  INVALID_AMOUNT: "Le montant est invalide.",
  INVALID_CARD_NUMBER: "Le numéro de carte est incorrect.",
  EXPIRED_CARD: "La carte est expirée.",
  LOST_CARD: "La carte est déclarée perdue.",
  STOLEN_CARD: "La carte est déclarée volée.",
  ACCOUNT_CLOSED: "Le compte associé à la carte est fermé.",
  INCORRECT_PIN: "Le code PIN est incorrect.",
  TRANSACTION_NOT_PERMITTED_TO_ENDUSER: "Paiement non autorisée pour ce compte",
  TRANSACTION_NOT_PERMITTED_TO_TERMINAL:
    "Paiement non autorisée pour ce terminal.",
  SUSPECTED_FRAUD: "Fraude suspectée.",
  RESTRICTED_CARD: "La carte est restreinte.",
  SECURITY_VIOLATION: "Violation de sécurité détectée.",
  EXCEEDS_LIMIT: "La transaction dépasse la limite autorisée.",
  EXCEEDS_WITHDRAWAL_LIMIT: "La transaction dépasse la limite de retrait.",
  EXCEEDS_WITHDRAWAL_FREQUENCY_LIMIT:
    "Le nombre de retraits a dépassé la limite autorisée.",
  VERIFICATION_DATA_FAILED: "Échec de la vérification des données.",
  CARDHOLDER_TO_CONTACT_ISSUER:
    "Le titulaire de la carte doit contacter l'émetteur.",
  PIN_TRIES_EXCEEDED: "Nombre de tentatives de PIN dépassé.",
  CARD_NOT_ACTIVE: "La carte n'est pas activée.",
  ISSUER_OR_SWITCH_INOPERATIVE:
    "L'émetteur ou le système de paiement est indisponible.",
  UNABLE_TO_ROUTE_TRANSACTION: "Impossible d'acheminer la transaction.",
  LAW_VIOLATION: "Violation de la loi.",
  SYSTEM_MALFUNCTION: "Défaillance du système.",
  STRONG_CUSTOMER_CARD_BASED_AUTHENTICATION_REQUIRED:
    "Authentification forte requise pour la carte.",
  STRONG_CUSTOMER_NON_CARD_BASED_AUTHENTICATION_REQUIRED:
    "Authentification forte requise pour la transaction.",
  DECLINE_FOR_CVV2_FAILURE:
    "La transaction est refusée en raison d'un échec CVV2.",
  UNKNOWN: "Erreur inconnue.",
};
