import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./auth/loginSlice";
import accountReducer from "./account/accountSlice";
import transacReducer from "./transaction/transactionSlice";
import statisticReducer from "./statistic/statisticSlice";
import accountStateReducer from "./accountState/accountStateSlice";
import cardReducer from "./card/cardSlice";
import teamReducer from "./team/teamSlice";
import settingReducer from "./setting/settingSlice";
import offerReducer from "./offer/offerSlice";
import informationReducer from "./information/informationSlice";
import ledgerReducer from "./ledger/ledgerSlice";
import memberReducer from "./member/memberSlice";
import logger from "redux-logger";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    account: accountReducer,
    transaction: transacReducer,
    statistic: statisticReducer,
    accountState: accountStateReducer,
    card: cardReducer,
    team: teamReducer,
    setting: settingReducer,
    offer: offerReducer,
    information: informationReducer,
    ledger: ledgerReducer,
    member: memberReducer,
  },
  middleware: (defaultMiddleWare) =>
    process.env.REACT_APP_NODE_ENV === "production"
      ? defaultMiddleWare()
      : defaultMiddleWare().concat(logger),
  devTools: process.env.REACT_APP_NODE_ENV !== "production",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
