import React, { FC } from "react";
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  Slide,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { X as CloseIcon } from "lucide-react";

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogPaper: {
      overflowY: "visible",
      padding: 0,
      margin: 0,
      "@media (max-width: 450px)": {
        "&.MuiDialog-paperFullWidth": {
          width: "calc(100% - 15px)",
        },
      },
      borderRadius: theme.shape.borderRadius * 3,
    },

    closeButton: {
      position: "absolute",
      top: 10,
      right: 16,
      opacity: 0.7,
      transition: "opacity 0.5s",
      "&:hover": {
        opacity: 1,
      },
    },
    header: {
      display: "flex",
      flexDirection: "column",
      "&.MuiDialog-paperFullWidth": {
        width: "calc(100% - 15px)",
      },
      padding: 0,
      margin: 0,
      //...
    },
    title: {
      paddingBottom: 0,
    },
    footer: {
      display: "flex",
      flexDirection: "column-reverse",
      // ...
      "@media (min-width: 600px)": {
        flexDirection: "row",
        justifyContent: "flex-end",
      },
    },
    content: {
      margin: 0,
      paddingTop: 0,
      marginBottom: 25,
    },
  }),
);

// Optionnel : Transition MUI (Slide, Fade, Grow...)
const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

// ---- Types ---- //
export interface MyDialogProps extends Omit<MuiDialogProps, "onClose"> {
  /** Contrôle l’ouverture du Dialog */
  open: boolean;
  /** Callback fermeture */
  onClose: () => void;
  /** Titre de la modale */
  dialogTitle?: React.ReactNode;
  /** Boutons de footer, etc. */
  footer?: React.ReactNode;
}

// ---- Composant principal ---- //
export const MyDialog: FC<MyDialogProps> = ({
  open,
  onClose,
  dialogTitle,
  children,
  footer,
  ...dialogProps
}) => {
  const classes = useStyles();

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      aria-labelledby="my-dialog-title"
      classes={{ paper: classes.dialogPaper }}
      {...dialogProps}
    >
      {/* Header */}

      <MuiDialogTitle disableTypography className={classes.title}>
        {!!dialogTitle && (
          <Typography
            variant="h6"
            className={classes.header}
            id="my-dialog-title"
          >
            {dialogTitle}
          </Typography>
        )}
        {/* Bouton de fermeture (X) */}
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon size={16} />
        </IconButton>
      </MuiDialogTitle>

      {/* Contenu principal */}
      <MuiDialogContent
        className={classes.content}
        //   dividers
      >
        {children}
      </MuiDialogContent>

      {/* Footer (actions) */}
      {footer && (
        <MuiDialogActions className={classes.footer}>{footer}</MuiDialogActions>
      )}
    </MuiDialog>
  );
};
