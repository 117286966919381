import gql from "graphql-tag";
import { LEDGER_FRAGMENT } from "./fragment";

export const LEDGER_INFO = gql`
  query LedgerInfo($linkcyId: String!) {
    ledgerInfo(linkcyId: $linkcyId) {
      ...LegderInfo
    }
  }
  ${LEDGER_FRAGMENT}
`;

export const ALL_LEDGERS = gql`
  query AllLedgers {
    allLedgers {
      ...LegderInfo
    }
  }
  ${LEDGER_FRAGMENT}
`;
