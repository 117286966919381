export default {
  BENEFICIARY_ACCOUNT_CLOSED_OR_STOPPED:
    "The beneficiary account is closed or blocked.",
  BENEFICIARY_INFORMATION_MISSING: "Beneficiary information is missing.",
  BENEFICIARY_INFORMATION_INVALID: "The beneficiary information is incorrect.",
  BENEFICIARY_NAME_DOES_NOT_MATCH_BENEFICIARY_ACCOUNT:
    "The provided name does not match the beneficiary account.",
  BENEFICIARY_NOT_ENABLED_FOR_INSTANT_PAYMENTS:
    "The beneficiary is not enabled for instant payments.",
  REFERENCE_INVALID: "The transaction reference is invalid.",
  SENDER_ACCOUNT_CLOSED: "The sender account is closed.",
  SENDER_INFORMATION_INVALID: "The sender information is incorrect.",
  AMOUNT_OR_CURRENCY_INVALID: "The amount or currency is invalid.",
  INSUFFICIENT_FUNDS: "Insufficient funds.",
  TRANSACTION_CURRENCY_DIFFERENT_TO_RECEIVER_ACCOUNT:
    "The transaction currency does not match the beneficiary account's currency.",
  LIMITS_EXCEEDED: "The transaction exceeds the allowed limits.",
  TRANSACTION_FAILED: "The transaction failed.",
  TRANSACTION_UNAUTHORISED: "The transaction is unauthorized.",
  TRANSACTION_CANCELLED: "The transaction was cancelled.",
  RETURNED_VIA_RECALL: "The transaction was returned via recall request.",
  REJECTED_BY_RECEIVING_BANK:
    "The beneficiary's bank rejected the transaction.",
  REJECTED_COMPLIANCE_REASON:
    "The transaction was rejected for compliance reasons.",
  REJECTED_NOT_ROUTABLE: "The transaction could not be routed properly.",
  REJECTED_NO_SPECIFIED_REASON:
    "The transaction was rejected without a specified reason.",
  REJECTED_ACCOUNT_CLOSED: "The affected account is closed.",
  REJECTED_ACCOUNT_INVALID: "The affected account is invalid.",
  REJECTED_TECHNICAL_ISSUE:
    "A technical issue caused the transaction to be rejected.",
  UNSUPPORTED_CARD_TYPE: "The card type is not supported.",
  UNSUPPORTED_ISSUING_COUNTRY: "The issuing country is not allowed.",
  THREEDS_ERROR: "A 3D Secure error occurred.",
  RISK_CHECK_ERROR: "The risk check failed.",
  MISSING_FIELD: "A required field is missing.",
  INVALID_FIELD: "A field contains incorrect information.",

  // CARD
  REFER_TO_CARD_ISSUER: "Please contact the card issuer.",
  INVALID_MERCHANT: "The merchant is invalid.",
  DO_NOT_HONOUR: "The transaction was declined.",
  INVALID_AMOUNT: "The amount is invalid.",
  INVALID_CARD_NUMBER: "The card number is incorrect.",
  EXPIRED_CARD: "The card is expired.",
  LOST_CARD: "The card is reported lost.",
  STOLEN_CARD: "The card is reported stolen.",
  ACCOUNT_CLOSED: "The account associated with the card is closed.",
  INCORRECT_PIN: "The PIN is incorrect.",
  TRANSACTION_NOT_PERMITTED_TO_ENDUSER:
    "Payment not permitted for this account.",
  TRANSACTION_NOT_PERMITTED_TO_TERMINAL:
    "Payment not permitted for this terminal.",
  SUSPECTED_FRAUD: "Suspected fraud.",
  RESTRICTED_CARD: "The card is restricted.",
  SECURITY_VIOLATION: "Security violation detected.",
  EXCEEDS_LIMIT: "The transaction exceeds the allowed limit.",
  EXCEEDS_WITHDRAWAL_LIMIT: "The transaction exceeds the withdrawal limit.",
  EXCEEDS_WITHDRAWAL_FREQUENCY_LIMIT:
    "The number of withdrawals has exceeded the allowed frequency.",
  VERIFICATION_DATA_FAILED: "Data verification failed.",
  CARDHOLDER_TO_CONTACT_ISSUER: "The cardholder must contact the issuer.",
  PIN_TRIES_EXCEEDED: "PIN attempts exceeded.",
  CARD_NOT_ACTIVE: "The card is not active.",
  ISSUER_OR_SWITCH_INOPERATIVE: "The issuer or payment system is inoperative.",
  UNABLE_TO_ROUTE_TRANSACTION: "Unable to route the transaction.",
  LAW_VIOLATION: "Law violation.",
  SYSTEM_MALFUNCTION: "System malfunction.",
  STRONG_CUSTOMER_CARD_BASED_AUTHENTICATION_REQUIRED:
    "Strong customer authentication required for the card.",
  STRONG_CUSTOMER_NON_CARD_BASED_AUTHENTICATION_REQUIRED:
    "Strong customer authentication required for the transaction.",
  DECLINE_FOR_CVV2_FAILURE:
    "The transaction was declined due to a CVV2 failure.",
  UNKNOWN: "Unknown error.",
};
