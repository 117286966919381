import { useLazyQuery } from "@apollo/client";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { bankingClient } from "../../../apolloClient";
import { useEffect, useCallback } from "react";
import { _myInformation, MyInformationItem } from "../../../indexDB/data";
import {
  LedgerInfo,
  LedgerInfo_ledgerInfo,
  LedgerInfoVariables,
} from "../../../graphql/graphql_banking/legder/types/LedgerInfo";
import { LEDGER_INFO } from "../../../graphql/graphql_banking/legder/query";
import { setMyLedger } from "../../../redux/ledger/ledgerSlice";

const useMyLedger = (synchronize?: boolean) => {
  const dispatch = useAppDispatch();

  const ledgerUsed = useAppSelector(
    (state) => state.information.myInformation.connected?.ledgerUsed,
  );
  const { allLedgers } = useAppSelector((state) => state.ledger);

  // Définition d'une fonction pour mettre à jour le store via Redux.
  const setData = useCallback(
    async (data: LedgerInfo_ledgerInfo) => {
      dispatch(setMyLedger(data));
    },
    [dispatch],
  );

  // Définition de la requête lazy avec gestion du cache et du callback onCompleted.
  const [getLedger, { loading, data }] = useLazyQuery<
    LedgerInfo,
    LedgerInfoVariables
  >(LEDGER_INFO, {
    fetchPolicy: "no-cache",
    client: bankingClient,
    onCompleted: async (data) => {
      if (data?.ledgerInfo) {
        await _myInformation.setItem(
          MyInformationItem.MY_LEDGER,
          data.ledgerInfo,
        );
        await setData(data.ledgerInfo);
      }
    },
  });

  // Fonction pour récupérer les données du ledger, en privilégiant le cache si synchronisation non requise.
  const fetchLedgerData = useCallback(
    async (linkcyId: string) => {
      const cache = await _myInformation.getItem(MyInformationItem.MY_LEDGER);
      if (cache && !synchronize) {
        await setData(cache as LedgerInfo_ledgerInfo);
      } else {
        getLedger({ variables: { linkcyId } });
      }
    },
    [getLedger, setData, synchronize],
  );

  // Choix du linkcyId à utiliser et déclenchement de la récupération des données.
  useEffect(() => {
    const linkcyId = ledgerUsed || allLedgers?.[0]?.linkcyId;
    if (linkcyId) {
      fetchLedgerData(linkcyId);
    }
  }, [ledgerUsed, allLedgers, fetchLedgerData]);

  return { getLedger, loading, data };
};

export default useMyLedger;
