import React from "react";
import { useSubscription } from "@apollo/client";
import { ON_BALANCE_UPDATE } from "../../../../graphql/graphql_banking/legder/subscription";
import {
  OnBalanceUpdate,
  OnBalanceUpdateVariables,
} from "../../../../graphql/graphql_banking/legder/types/OnBalanceUpdate";
import { bankingClient } from "../../../../apolloClient";
import { _myInformation } from "../../../../indexDB/data";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { setMyLedger } from "../../../../redux/ledger/ledgerSlice";
import { useCreateOrUpdateLedgerCache } from "../../../NewAccount/tools";

const LedgerSub = (props: { corporateLinkcyId: string }) => {
  const { corporateLinkcyId } = props;
  const dispatch = useAppDispatch();
  const { myLedger } = useAppSelector((state) => state.ledger);
  const { updateOrCreate } = useCreateOrUpdateLedgerCache();

  useSubscription<OnBalanceUpdate, OnBalanceUpdateVariables>(
    ON_BALANCE_UPDATE,
    {
      variables: {
        corporateLinkcyId,
      },
      client: bankingClient,
      onSubscriptionData: ({ subscriptionData: { data } }) => {
        if (data?.onBalanceUpdate) {
          if (
            !myLedger ||
            myLedger?.linkcyId === "-" ||
            myLedger?.linkcyId === data?.onBalanceUpdate?.linkcyId
          ) {
            dispatch(setMyLedger(data.onBalanceUpdate));
          }
          updateOrCreate(data?.onBalanceUpdate);
        }
      },
    },
  );

  return <></>;
};

export default LedgerSub;
