import { createTheme } from "@material-ui/core/styles";
import { PaletteType } from "@material-ui/core/";
import "./index.css";

export const colorLight = {
  divider: "#E8E7F5",
  appBackground: "#FAFAFC",
};

export const colorDark = {
  divider: "#5C5B5C",
  appBackground: "#1F2334",
};

const defaultTheme = createTheme();

const theme = (modTheme: PaletteType) => {
  return {
    theme: createTheme({
      palette: {
        type: modTheme ? modTheme : "light",
        primary: {
          main: "#329AEC",
        },
        text: {
          primary:
            modTheme === "light"
              ? "#252551"
              : defaultTheme.palette.background.paper,
          secondary: modTheme === "light" ? "#6B7280" : "#ADADAD",
        },
        background: {
          paper:
            modTheme === "light"
              ? defaultTheme.palette.background.paper
              : "#262A3E",
        },
        error: {
          main: "#f44336",
        },
      },
      typography: {
        fontFamily:
          "ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji, Montserrat",

        h1: { fontSize: "1.625rem" },
        h2: { fontSize: "1.25rem", lineHeight: 1.5 },
        h3: { fontSize: "1.125rem" },
        h6: { fontSize: "1rem" },
        body1: {
          fontSize: ".875rem",
          lineHeight: 1.5,
        },
        body2: {
          fontSize: ".75rem",
          lineHeight: 1.5,
        },
      },
      overrides: {
        MuiPaper: {
          elevation3: {
            boxShadow:
              "0px 3px 3px -2px rgba(0,0,0,0.1), 0px 3px 4px 0px rgba(0,0,0,0.05), 0px 1px 8px 0px rgba(0,0,0,0.1)",
          },
        },
        MuiSvgIcon: {
          colorPrimary: {
            color: "#329AEC",
          },
          colorSecondary: {
            color: "#252551",
          },
        },
        MuiAppBar: {
          colorDefault: {
            color: "#252551",
          },
        },
        MuiBadge: {
          colorPrimary: {
            backgroundColor: "#EF0006",
          },
          anchorOriginTopRightRectangle: {
            transform: "scale(.8) translate(45%, -30%)",
          },
        },
        MuiOutlinedInput: {
          root: {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderWidth: 2,
              borderColor: "#329AEC",
            },
          },
          input: {
            height: "2.1875rem",
            padding: defaultTheme.spacing(0, 1.75),
            fontSize: ".75rem",
          },
          notchedOutline: {
            transition: "all ease .2s",
            borderColor: modTheme === "light" ? "#A3A8B0" : "#FFF",
          },
        },
        MuiInputLabel: {
          outlined: {
            color: modTheme === "light" ? "#A3A8B0" : "#FFF",
            transform: "translate(14px, 12px) scale(.85)",
          },
        },
        MuiButton: {
          root: {
            textTransform: "unset",
            borderRadius: 6,
          },
          contained: {
            padding: "7px 20px",
            fontSize: ".875rem",
            fontWeight: 400,
          },
        },
        MuiFormControlLabel: {
          label: {
            fontSize: ".625rem",
          },
        },
        MuiCardActionArea: {
          focusHighlight: {
            backgroundColor: defaultTheme.palette.common.white,
          },
        },
        MuiSelect: {
          select: {
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
        },
        MuiMenu: {
          paper: {
            marginTop: 45,
            maxHeight: 148,
            overflowY: "scroll",
          },
        },
        MuiTableCell: {
          stickyHeader: {
            backgroundColor: "transparent",
          },
        },
        MuiStepper: {
          root: {
            padding: defaultTheme.spacing(1, 0),
          },
        },
        MuiStepLabel: {
          labelContainer: {
            "&>span": {
              fontSize: ".75rem !important",
            },
          },
        },
        MuiStepIcon: {
          root: {
            color: "#1B998B!important",
          },
          active: {
            color: "#1B998B !important",
          },
          completed: {},
        },
        MuiStepConnector: {
          line: {
            borderColor: "#1B998B",
          },
        },
      },
    }),
    mode: modTheme,
  };
};

export default theme;
