import { LEDGER_STATUS } from "../../enums/ledger";
import { LedgerInfo_ledgerInfo } from "../../graphql/graphql_banking/legder/types/LedgerInfo";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { setAllLedger } from "../../redux/ledger/ledgerSlice";
import { addOrUpdateObject, removeObject } from "../../utils/utils";

export const useCreateOrUpdateLedgerCache = () => {
  const dispatch = useAppDispatch();
  const { allLedgers } = useAppSelector((state) => state.ledger);

  const updateOrCreate = (ledger: LedgerInfo_ledgerInfo) => {
    // update list ledger
    const { status, id } = ledger;
    let newList = allLedgers;
    if (
      status == LEDGER_STATUS.ACTIVE ||
      status == LEDGER_STATUS.PENDING ||
      status == LEDGER_STATUS.SUSPENDED
    ) {
      // update list transaction validated
      newList = addOrUpdateObject(allLedgers, ledger);
    } else if (
      status == LEDGER_STATUS.CLOSED ||
      status == LEDGER_STATUS.CLOSING ||
      status == LEDGER_STATUS.FAILED
    ) {
      newList = removeObject(allLedgers, { id });
    }
    dispatch(setAllLedger([...newList]));
  };

  return { updateOrCreate };
};
