import React, { forwardRef } from "react";
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // Styles de base, communs à tous les boutons
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(1),
      whiteSpace: "nowrap",
      minWidth: 0,
      borderRadius: theme.shape.borderRadius * 2,
      fontSize: "0.875rem", // ~ text-sm
      fontWeight: 500,
      transition: "background-color 0.5s, color 0.5s",
      "&:focus-visible": {
        outline: "none",
        boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
      },
      "&:disabled": {
        pointerEvents: "none",
        opacity: 0.5,
      },
      // Equivalent des classes "[&_svg]:pointer-events-none"
      "& svg": {
        pointerEvents: "none",
        width: 16,
        height: 16,
        flexShrink: 0,
      },
      "@media (max-width: 450px)": {
        fontSize: "0.775rem",
      },
    },
    fullWidth: {
      width: "100%",
    },
    defaultWidth: {
      width: "fit-content",
    },
    // --- Variants ---
    default: {
      background: "linear-gradient(180deg, #1E88E5 0%, #00BCD4 100%)", // "bg-primary"
      color: theme.palette.primary.contrastText, // "text-primary-foreground"
      "&:hover": {
        background: theme.palette.primary.dark,
      },
    },
    destructive: {
      backgroundColor: theme.palette.error.main, // "bg-destructive"
      color: theme.palette.error.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
    },
    outline: {
      border: `1px solid ${theme.palette.divider}`, // "border border-input"
      backgroundColor: "transparent", // "bg-background"
      "&:hover": {
        backgroundColor: theme.palette.action.hover, // "hover:bg-accent"
        // color: ...
      },
    },
    secondary: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    primary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    ghost: {
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: theme.palette.action.hover, // "hover:bg-accent"
      },
    },
    link: {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "none", // ou "underline"
      },
    },
    outline2: {
      border: `1px solid ${theme.palette.primary.light}`,
      color: theme.palette.primary.main, // "border border-input"
      backgroundColor: "transparent", // "bg-background"
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText, // "hover:bg-accent"
        // color: ...
      },
    },
    outline3: {
      border: `1px solid ${theme.palette.secondary.main}`,
      color: theme.palette.secondary.main, // "border border-input"
      backgroundColor: "transparent", // "bg-background"
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText, // "hover:bg-accent"
      },
    },
    outline4: {
      border: `1px solid rgb(191 219 254 / 1)`,
      color: "rgb(98, 153, 221)", // "border border-input"
      backgroundColor: "transparent", // "bg-background"
      "&:hover": {
        backgroundColor: "rgb(239 246 255 / 1)",
        color: theme.palette.primary.dark, // "hover:bg-accent"
      },
    },

    // --- Sizes ---
    sizeDefault: {
      height: 40,
      padding: theme.spacing(0, 2), // px-4
      "@media (max-width: 450px)": {
        height: 33,
        padding: theme.spacing(0, 1.75),
      },
    },
    sizeSm: {
      height: 36,
      borderRadius: 4, // "rounded-md"
      padding: theme.spacing(0, 1.5),
    },
    sizeLg: {
      height: 44,
      borderRadius: 4,
      padding: theme.spacing(0, 3),
    },
    small: {
      height: 33,
      padding: theme.spacing(0, 1.75),
      fontSize: "0.775rem !important",
    },
    icon: {
      width: 40,
      height: 40,
      padding: 0,
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    fullWitdhtButton: {
      "@media (max-width: 768px)": {
        width: "100%",
        marginBottom: 10,
      },
    },
  }),
);

// Définition de nos variants
export type ButtonVariant =
  | "default"
  | "destructive"
  | "outline"
  | "secondary"
  | "primary"
  | "ghost"
  | "link"
  | "outline2"
  | "outline3"
  | "outline4";

export type ButtonSize = "default" | "sm" | "lg" | "icon" | "small";

export interface CustomButtonProps
  extends Omit<MUIButtonProps, "color" | "variant" | "size"> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  loading?: boolean;
  label: string;
  fullWidthMobile?: boolean;
  iconShowMobile?: boolean;
}

/**
 * CustomButton :
 * - Gère nos variants (default, destructive...) & sizes (default, sm, lg, icon)
 * - Hérite du MUIButton pour rester cohérent avec MUI (ripple, disabled, etc.)
 */
export const CustomButton = forwardRef<HTMLButtonElement, CustomButtonProps>(
  (
    {
      variant = "default",
      size = "default",
      className,
      fullWidth,
      loading,
      disabled,
      label,
      fullWidthMobile,
      iconShowMobile,
      ...props
    },
    ref,
  ) => {
    const classes = useStyles();
    const isMobile = useMediaQuery("(max-width: 260px)");
    // On va construire la classe de style en fonction du variant & size
    const variantClass = classes[variant] || classes.default;
    let sizeClass = "";
    switch (size) {
      case "sm":
        sizeClass = classes.sizeSm;
        break;
      case "lg":
        sizeClass = classes.sizeLg;
        break;
      case "icon":
        sizeClass = classes.icon;
        break;
      case "small":
        sizeClass = classes.small;
        break;
      default:
        sizeClass = classes.sizeDefault;
        break;
    }

    return (
      <MUIButton
        ref={ref}
        className={clsx(
          classes.root,
          variantClass,
          sizeClass,
          className,
          fullWidth ? classes.fullWidth : classes.defaultWidth,
          fullWidthMobile && classes.fullWitdhtButton,
        )}
        disabled={loading || disabled}
        // On ignore la prop color/variant de MUI pour utiliser la nôtre
        {...props}
      >
        {!(iconShowMobile && isMobile) && <FormattedMessage id={label} />}

        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </MUIButton>
    );
  },
);

CustomButton.displayName = "CustomButton";
