import Card from "./card";
import Transaction from "./transaction";
import Beneficiary from "./beneficiary";
import DeclineReason from "./declineReason";
export default {
  ...Card,
  ...Transaction,
  ...Beneficiary,
  ...DeclineReason,
};
