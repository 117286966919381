const dataBaseName = "SapheerDatabase";
export const indexedDBCount = async (name: string): Promise<number> => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(dataBaseName, 3);
    request.onerror = (event: any) => {
      console.log("Why didn't you allow my web app to use IndexedDb!!");
      console.log("Database error: ", event.target.errorCode);
      reject(null);
    };
    request.onsuccess = (event: any) => {
      const db = event.target.result;
      const store = db.transaction([name]).objectStore(name);
      const count = store.count();
      count.onsuccess = () => {
        resolve(count.result);
      };
    };
  });
};

export const indexedDBGetAll = async (
  name: string,
  query?: IDBKeyRange,
  count?: number,
): Promise<[]> => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(dataBaseName, 3);
    request.onerror = (event: any) => {
      console.log("Why didn't you allow my web app to use IndexedDb!!");
      console.log("Database error: ", event.target.errorCode);
      reject([]);
    };
    request.onsuccess = async (event: any) => {
      const db = event.target.result;
      const store = db.transaction([name]).objectStore(name);
      const getAll = await store.getAll();
      return (getAll.onsuccess = () => {
        resolve(getAll.result);
      });
    };
  });
};

export const indexedDBGet = async (name: string, key: number): Promise<{}> => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(dataBaseName, 3);
    request.onerror = (event: any) => {
      console.log("Why didn't you allow my web app to use IndexedDb!!");
      console.log("Database error: ", event.target.errorCode);
      reject([]);
    };
    request.onsuccess = async (event: any) => {
      const db = event.target.result;
      const store = db.transaction([name]).objectStore(name);
      const getAll = await store.get(key);
      return (getAll.onsuccess = () => {
        resolve(getAll.result);
      });
    };
  });
};

export const indexedDBClearData = async (name: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(dataBaseName, 3);
    if (request) {
      request.onerror = (event: any) => {
        console.log(
          "Why didn't you allow my web application to use IndexedDB!",
        );
        console.log("Database error: ", event.target.errorCode);
        reject(false);
      };
      request.onsuccess = async (event: any) => {
        const db = event.target.result;
        // Check if the object store exists before performing a transaction on it
        if (!db.objectStoreNames.contains(name)) {
          resolve(false);
          return;
        }
        const transaction = db.transaction([name], "readwrite"); // Switch to READ_WRITE mode
        const store = transaction.objectStore(name);
        const clearRequest = store.clear();
        clearRequest.onsuccess = () => {
          resolve(true);
        };
        clearRequest.onerror = () => {
          reject(false);
        };
      };
      request.onupgradeneeded = (event: any) => {
        // Here, you can handle the database upgrade if necessary
        // For example, you can create the object store here if it doesn't exist
      };
    }
  });
};
