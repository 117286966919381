export default {
  maintenance: "Sapheer est en maintenance, revenez dans quelques minutes",
  "file.type": "Type de fichier",
  "maintenance.title": `Maintenance en cours`,
  "maintenance.subtitle1": `Nous améliorons notre plateforme`,
  "maintenance.subtitle2": `Revenez dans quelques minutes`,
  disconnect: "Déconnexion",
  setting: "Paramètres",
  or: "Ou",
  "user.confirmation.deconnect": `Votre session a expiré, vous allez être déconnecté. Voulez-vous rester connecté ?`,
  "img.load": "Chargez",
  year: "Année",
  title: "Tableau de bord",
  accept: "Accepter",
  stop: "Stop",
  reset: "Réinitialiser",
  "error.notEmptyField": "Ce champ ne peut être vide.",
  "server.error": "Erreur Serveur",
  "network.error": "Erreur Connexion",
  "other.error": "Erreur",
  "error.uploadOnlyOneFile": "Veuillez choisir un seul fichier",
  "error.uploadFileInvalid": "Veuillez choisir un fichier de type image ou pdf",
  "error.uploadTooLarge": "Fichier trop large",
  "error.uploadTooSmall": "Fichier trop petit",
  "error.uploadFile": `Erreur lors de l'envoi du fichier`,
  "error.invalidEmailAddress": "Adresse email invalide.",
  "error.getMe": "Erreur lors de la récupération des informations du compte.",
  "error.getRoleRights":
    "Erreur lors de la récupération du rôle et des autorisations.",
  "error.networkError":
    "Erreur réseau, veuillez vérifier votre connexion internet.",
  "error.unknownError":
    "Un problème inconnu est survenu, veuillez réessayer ultérieurement.",
  "error.nothingSelected": "Cette sélection ne peut être vide.",
  "error.verifyPassword": "Erreur lors de la vérification du mot de passe.",
  "error.getCompanyInfo":
    "Erreur lors de la récupération des informations de la compagnie.",
  save: "Enregistrer",
  validate: "Valider",
  cancel: "Annuler",
  edit: "Modifier",
  citizenship: "Citoyenneté",
  dateBirth: "Date de naissance",
  countryBirth: "Pays de naissance",
  cityBirth: "Ville de naissance",
  legalName: "Raison sociale",
  legalForm: "Forme juridique",
  legalRegistrationNumber: "SIRET",
  address: "Adresse",
  postCode: "Code postal",
  city: "Ville",
  informations: "Informations",
  contactInformation: "Coordonnées",
  "label.uploadImage": "Charger une image",
  "label.activation": "Activation",
  "label.role": "Rôle",
  "label.team": "Équipe",
  "label.name": "Nom",
  "label.firstName": "Prénom",
  "label.emailAddress": "Adresse email",
  "label.member": "Membre",
  greeting: "Salut, ",
  command: "Commander",
  previous: "Précédent",
  next: "Suivant",
  finish: "Terminer",
  "label.directionTeam": "Direction",
  "label.marketing": "Marketing",
  "label.finance": "Finance",
  "label.informatique": "Informatique",
  "label.commercial": "Commerciales",
  "label.admin": "Admin",
  "label.contributor": "Collaborateur",
  "label.accountant": "Comptable",
  "label.none": "FILTRE",
  "label.virtual": "Virtuelle",
  "label.physical": "Physique",
  "label.activate": "Active",
  "message.yourOffer": "Votre offre",
  "label.numberOfLine": "Nombre de lignes",
  "label.of": "sur",
  "404":
    "La page que vous cherchez n'existe pas. Merci de contacter l'administrateur.",
  "404.button": "revenir à l'accueil",
  expirate:
    "Il semblerait que votre lien soit expiré. Merci de renouveler votre demande en contactant le responsable. ",
  expenses: "Sortant",
  recipes: "Entrant",
  LAST_3_DAYS: "3 derniers jours",
  LAST_7_DAYS: "7 derniers jours",
  LAST_10_DAYS: "10 derniers jours",
  LAST_15_DAYS: "15 derniers jours",
  LAST_MONTH: "dernier mois",
  LAST_2_MONTHS: "2 derniers mois",
  LAST_3_MONTHS: "3 derniers mois",
  member: "Membre",
  all: "Tous",
  period: "Période",
  category: "Catégorie",
  flux: "Flux",
  incoming: "Entrant",
  outgoing: "Sortant",
  femaleAll: "Toutes",
  activate: "Activé",
  notActivate: "Désactivé",
  none: "Aucun",
  femaleNone: "Aucune",
  alls: "Tout",
  "text.providePassword":
    "Vous devez fournir votre mot de passe pour appliquer vos modifications.",
  sender: "Expéditeur :",
  nextTime: "Prochain :",
  confirm: "Confirmer",
  close: "Fermer",
  "mail.confirmation":
    "Un mail a été envoyé, contenant le code de confirmation.",
  "code.confirmed": "Votre code est valide.",
  "code.error": "Il se peut que votre code soit erroné ou n'est plus valide.",
  details: "Détails",
  date: "Date",
  download: "Télécharger",
  "search.noResult":
    "Oops, \n Nous n'avons trouvé aucun résultat qui correspond à votre recherche.",
  logout: "Déconnexion",
  notValidatedCompany: `Votre compte est en cours de validation sous 48 heures. Nous vous prévenons dès que cela est finalisé par nos équipes.Team Sapheer`,
  "error.fileNotSelected": "Veuillez choisir un fichier.",

  "build.content": "Bientôt disponible",
  "form.tel": "Numéro de téléphone",

  "phoneNumber.invalid": "Le numéro de téléphone doit commencer par 00 ou + ",
  "phone.empty": "Veuillez renseigner votre numéro de téléphone",

  "error.getUserFeature": `Erreur lors de la vérification de l'authorisation de l'utilisateur`,

  "feature.disabled": `Votre compte a été désactivé. Veuillez contacter l'équipe Sapheer`,
  "paiement.waiting": `Veuillez patienter...`,
  startDate: "Date de début",
  endDate: "Date fin",
  CANCELED: "Annulé",
  VALIDATED: "Validé",
  PENDING: "En attente",
  "label.day": "Jour",
  "label.week": "Semaine",
  "download.file.error": "Erreur lors du téléchargement du fichier.",
  "account.can.t.generate.statement": `Vous n'avez pas le droit de générer le relevé de compte`,
  "sca.content.1": `<text>Télécharger</text> l’application <text>Linkcy Authenticator</text> sur Apple Store ou Play Store puis scanner ce <text>QR Code</text> ou saisir le <text>code</text> ci-dessous.`,
  "sca.content.2": `<text>Actualiser</text> votre navigateur si la fenêtre ne disparaît pas <text>au bout de 60 secondes</text>.`,
  "sca.title": `Validation Obligatoire`,
  "sca.valid.instruction": `Ouvrez l'application <text>Linkcy Authenticator</text> pour valider votre <text>requête</text>.`,
  "delete.sca": `Supprimer SCA`,
  "detele.sca.success": `SCA supprimé avec succès`,
  "delete.sca.error": `Erreur lors de la suppression de SCA`,
  "delete.sca.confirmation": `Confirmer la suppression`,
  "amount.balance": "Solde",
  "transaction.daily.limit.used": "Sortant : Total journalier",
  "transaction.monthly.limit.used": "Sortant : Total mensuel",
};
