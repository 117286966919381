import React, { KeyboardEvent, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useIntl } from "react-intl";
import { useLogout } from "../../../Auth/Logout/Logout";
import { MyDialog } from "../../../Ui/MyDialog";
import {
  Box,
  createStyles,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { CustomButton } from "../../../Ui/CustomButton";

interface Props {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      "& > *:not(:last-child)": {
        marginBottom: theme.spacing(2),
      },
    },
    title: {
      textAlign: "center",
      fontSize: "1rem",
      "& .MuiTypography-h6": {
        fontSize: "1.15rem !important",
      },
    },
    textContainer: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      "& > *:not(:last-child)": {
        marginBottom: theme.spacing(1),
      },
    },
    infoText: {
      fontSize: "0.875rem", // équivalent à text-sm
      color: theme.palette.text.secondary, // simule text-muted-foreground
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      // simulation de gap-2 : marge entre boutons
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(2),
      },
    },
  }),
);

const UserInactivity = (props: Props) => {
  const { children } = props;
  const classes = useStyles();
  const intl = useIntl();
  const [confirmation, setConfirmation] = useState(false);
  const [time, setTime] = useState<any>(null);
  const confirmationTime = 1000 * 60; // 1min

  // ---- logout apres 10 minutes d'inactivité
  const inactivityTimer = 1000 * 60 * 10;
  const { logout } = useLogout();

  const handleOnIdle = (event: any) => {
    setConfirmation(true);
    setTime(
      setTimeout(() => {
        logout();
      }, confirmationTime),
    );
  };

  const handleOnActive = (event: any) => {};
  const handleClose = () => {
    clearTimeout(time);
    setConfirmation(false);
  };
  const handleOnAction = (e: any) => {};

  useIdleTimer({
    timeout: inactivityTimer,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  const handleKeyPress = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleClose();
    }
  };
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <MyDialog
        open={confirmation}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        dialogTitle={
          <DialogTitle className={classes.title}>
            {intl.formatMessage({ id: "disconnect" })}
          </DialogTitle>
        }
      >
        <Box className={classes.root} onKeyPress={handleKeyPress}>
          <Box className={classes.textContainer}>
            <Typography className={classes.infoText}>
              {intl.formatMessage({ id: "user.confirmation.deconnect" })}
            </Typography>
          </Box>

          {/* Boutons */}
          <Box className={classes.buttonContainer}>
            <CustomButton
              variant="primary"
              label="confirm"
              onClick={handleClose}
              fullWidthMobile
            />
          </Box>
        </Box>
      </MyDialog>
      {children}
    </div>
  );
};

export default UserInactivity;
